<template>
  <div class="form-input">
    <label v-if="label" :class="{ invalid: isInvalid }" :for="id">{{
      label
    }}</label>
    <input
      :class="{ invalid: isInvalid }"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      :value="value"
      @input="input"
    />
  </div>
</template>

<script>
export default {
  name: "FormInput",

  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: String,
    label: String,
    required: {
      type: Boolean,
      default: true
    },
    isInvalid: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      id: "input-" + Math.random()
    };
  },

  methods: {
    input(event) {
      this.$emit("input", event.target.value);
    }
  }
};
</script>
