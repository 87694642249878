<template>
  <div class="app__login">
    <form @submit.prevent="authHandler">
      <div id="logo">
        <img src="../assets/images/logo.svg" alt="Логотип" />
      </div>
      <h1>ДИСПЕТЧЕРСКАЯ<br />автопарк</h1>
      <form-input
        v-model="username"
        label="Логин"
        placeholder="7777777777"
        :isInvalid="isInvalidUsername"
      ></form-input>
      <form-input
        v-model="password"
        label="Пароль"
        type="password"
        placeholder="*********"
        :isInvalid="isInvalidPassword"
      ></form-input>
      <div id="forgetPass">
        <router-link to="/forget">Не помню</router-link>
      </div>
      <form-button
        title="ВОЙТИ"
        class="button__yellow"
        :disabled="disabled"
      ></form-button>
      <ui-message
        @messageClose="messageClose"
        :message="message"
        :messageProp="messageProp"
      />
    </form>
  </div>
</template>

<script>
import FormInput from "../components/form/form-input.vue";
import FormButton from "../components/form/form-button.vue";
import UiMessage from "../components/ui/ui-message.vue";

import { mapActions } from "vuex";

export default {
  name: "Auth",
  components: {
    FormInput,
    FormButton,
    UiMessage
  },
  data() {
    return {
      username: "",
      password: "",
      isInvalidPassword: false,
      isInvalidUsername: false,
      disabled: false,
      message: false,
      messageProp: {}
    };
  },
  computed: {
    clearLogin() {
      let clearLogin = "";
      if (this.username) {
        clearLogin = this.username.replace(/[^0-9]/g, "");
      }
      return clearLogin;
    }
  },
  methods: {
    ...mapActions(["GET_TOKEN", "GET_TOKEN_REFRESH"]),

    clearForm() {
      this.username = "";
      this.password = "";
      this.isInvalidPassword = false;
      this.isInvalidUsername = false;
      this.disabled = false;
    },

    formIsValid() {
      let isValid = true;
      if (this.username.length === 0 || this.password.length === 0) {
        isValid = false;
      }
      return isValid;
    },

    authHandler() {
      if (this.formIsValid) {
        this.disabled = true;
        this.axios
          .post("/api/login_check", {
            username: this.username,
            password: this.password
          })
          .then((response) => {
            this.GET_TOKEN(response.data.token);
            this.GET_TOKEN_REFRESH(response.data.refresh_token);
            this.$router.push("/");
          })
          .catch((e) => {
            console.log(e);
            if (e.response.status === 401) {
              (this.message = true),
                (this.messageProp = {
                  class: "alert__danger",
                  title: "Неверные данные для входа",
                  text: "Попробуйте ввести заново"
                });
            }
          })
          .finally(() => {
            this.clearForm();
          });
      }
    },

    messageClose() {
      this.message = false;
      this.messageProp = {};
    }
  }
};
</script>
